import {
  cache,
  createAsync,
  useParams,
  useSearchParams,
} from "@solidjs/router";
import { onMount, Show } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import RedeemGiftCardComponent from "~/components/gifting/brand-collection-redeem/brand_collection_redeem";
import { getGiftBoxV3RedeemRouteData } from "~/server/data/gifting_v3/gift_box_v3_redeem_route_data";
import attachPopStateListener from "~/utils/popstate_listener";

const RedeemGiftCardRoute$C = cache(
  getGiftBoxV3RedeemRouteData,
  "GiftBoxV3RedeemGiftCardRoute"
);

export default function RedeemGiftCardRoute() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const giftingKey = params.giftingKey$ as string;
  const brandKey = params.brandKey as string;
  const routeData = createAsync(
    async () => {
      return await RedeemGiftCardRoute$C({
        giftingKey,
        brandKey,
      });
    },
    { deferStream: true }
  );

  onMount(() => {
    attachPopStateListener();
  });

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />
      <ClientOnlyComponent component={ClientComponent.ModalHost} />

      <Show when={routeData()}>
        <RedeemGiftCardComponent
          brand={routeData()!.brand}
          onOrderInitiateSuccess={async (orderId) => {
            window.location.replace(
              "/gift-box/brand-collection/" +
                giftingKey +
                "/buy/" +
                brandKey +
                "/" +
                orderId
            );
          }}
          coinsAvailable={routeData()!.coinsCount}
          giftingKey={giftingKey}
          phoneLinked={routeData()!.isPhoneNumberLinked}
          isRtu={routeData()!.isRtu}
          logoUrl={routeData()!.logoUrl}
        />
      </Show>
    </>
  );
}
