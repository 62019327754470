import { DenominationsGrid } from "~/components/brand_l2/denominations_grid";
import { BrandCollectionHeader } from "../brand-collection-landing/brand_collection_header";
import { GiftFooter } from "../gift_footer";
import { createEffect, createSignal } from "solid-js";
import { Header, Optional } from "~/types";
import { DiscountDetailsItem, VoucherProduct } from "~/server/types/brand";
import { Spacer } from "~/widgets/spacer";
import HubbleImage from "~/widgets/hubble_image";
import { createJob } from "~/utils/job";
import { showSnackBar } from "~/shared_states/snackbar";
import { initiateRedemption } from "~/server/apis/client_apis";
import LocalStorageUtil from "~/utils/local_storage";
import {
  getV3GiftingDataStorageKey,
  storeV3GiftSessionId,
} from "~/components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import { APIError } from "~/utils/fetch";
import { verifyGiftV3 } from "../verification/verification_v3";
import { VerifyOtpResponseV3 } from "~/server/types/gift";
import { generateDeviceVerificationToken } from "~/utils/common";
import { BorderGradient } from "../gift_card";
import { CardGiftV3 } from "../brand-collection-landing/brand_collection_components";
import { coinIcon } from "~/assets/assets";
import { toIndianNumber } from "~/utils/number";

export type RedeemGiftCardProps = {
  brand: VoucherProduct;
  onOrderInitiateSuccess: (orderId: string) => Promise<void>;
  coinsAvailable: number;
  giftingKey: string;
  phoneLinked: boolean;
  isRtu: boolean;
  logoUrl?: string;
};

export default function RedeemGiftCardComponent(props: RedeemGiftCardProps) {
  const [ctaState, setCtaState] = createSignal<CtaState>("DEFAULT");
  const [selectedVariantId, setSelectedVariantId] =
    createSignal<Optional<string>>("");

  const [selectedAmount, setSelectedAmount] = createSignal<Optional<number>>(
    props.brand.amountConditions.defaultAmount || 500
  );
  const [redemptionAmount, setRedemptionAmount] =
    createSignal<Optional<number>>(selectedAmount());

  const [dryRunErrorMessage, setDryRunErrorMessage] =
    createSignal<Optional<string>>(null);

  createEffect(() => {
    if (selectedAmount() === null) {
      return;
    }
    if (selectedAmount()! > props.brand.amountConditions.maxAmount) {
      setDryRunErrorMessage(
        `Maximum redemption amount for this brand is ${props.brand.amountConditions.maxAmount}`
      );
      return;
    }

    if (selectedAmount()! < props.brand.amountConditions.minAmount) {
      setDryRunErrorMessage(
        `Minimum redemption amount for this brand is ${props.brand.amountConditions.minAmount}`
      );
      return;
    }
    setDryRunErrorMessage(null);
    setRedemptionAmount(selectedAmount());
  });

  let initiateOrderJob = createJob({
    initialJob: async () => {
      setCtaState("LOADING");
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      let response = await initiateRedemption(
        {
          productId: props.brand.externalId,
          variantId: selectedVariantId() ?? "",
          amount: selectedAmount()!,
          consumeCoins: true,
          paymentMode: "UPI",
          ...(props?.brand.amountConditions.denominations != null
            ? {
                denominationDetails: [
                  {
                    denomination: selectedAmount()!,
                    quantity: 1,
                  },
                ],
              }
            : {}),
        },
        {
          [Header.SessionId]: LocalStorageUtil.getItem(
            getV3GiftingDataStorageKey(props.giftingKey, "sessionId")
          ),
        }
      );
      return await props.onOrderInitiateSuccess(response.storeOrderDetails.id);
    },
    successCallback: async () => {
      setCtaState("DEFAULT");
      console.log("Order initiated successfully");
    },
    errorCallback: async (error: APIError) => {
      setCtaState("DEFAULT");
      showSnackBar({
        message: error.message,
        level: "error",
      });
      if (error.code === 401) {
        window.location.href = "/gift-box/brand-collection/" + props.giftingKey;
      }
    },
  });

  async function onAttemptRedemption() {
    if (props.phoneLinked) {
      initiateOrderJob.run();
    } else {
      let deviceVerificationToken = await generateDeviceVerificationToken();
      verifyGiftV3<VerifyOtpResponseV3>({
        deliveryMethod: "INSTANT",
        deviceVerificationToken: deviceVerificationToken,
        giftId: props.giftingKey,
        onVerificationSuccess: (response) => {
          storeV3GiftSessionId({
            giftingKey: props.giftingKey,
            sessionId: response.sessionId,
          });
          initiateOrderJob.run();
        },
      });
    }
  }

  return (
    <div class=" relative flex min-h-screen flex-col items-center justify-start bg-black">
      <BrandCollectionHeader
        showBalance={() => true}
        showProfile={() => props.isRtu}
        isAbsolute={false}
        coinsAvailable={() => props.coinsAvailable}
        giftingKey={props.giftingKey}
        logoUrl={props.logoUrl}
      />
      <div class="noScrollbar dark flex w-full max-w-[600px] flex-col items-center justify-start overflow-y-scroll ">
        <Spacer height={60} />
        <div class="w-full px-5">
          <BorderGradient>{buildDenominationGrid()}</BorderGradient>
        </div>

        <Spacer height={12} />
        <GiftFooter
          brand={{
            brandId: props.brand.externalId,
            tncResId: props.brand.voucherProductMetadata.tncResId,
            brandKey: props.brand.brandKey,
            brandName: props.brand.voucherProductMetadata.title,
            cardBackgroundColor:
              props.brand.voucherProductMetadata.cardBackgroundColor,
            deeplink: props.brand.voucherProductMetadata.addVoucherBrandPageUrl,
            heroImageUrl: props.brand.voucherProductMetadata.heroImageUrl,
            plainLogoUrl: props.brand.voucherProductMetadata.plainLogoUrl,
          }}
        />
      </div>
    </div>
  );

  type CtaState = "DEFAULT" | "LOADING" | "DISABLED";

  function buildDenominationGrid() {
    const [useRewardCoins, setUseRewardCoins] = createSignal<boolean | null>(
      false
    );
    const [hubbleCoinRewards, setHubbleCoinRewards] =
      createSignal<DiscountDetailsItem | null>(null);

    let brand = modifyBrandAmountConditions({
      brand: props.brand,
      coinsAvailable: props.coinsAvailable,
    });

    return (
      <DenominationsGrid
        discountPercentage={0}
        isRewardTypePostPaid={false}
        dryRunErrorMessage={dryRunErrorMessage}
        selectedAmount={selectedAmount}
        setSelectedAmount={setSelectedAmount}
        selectedVariantId={selectedVariantId}
        setSelectedVariantId={setSelectedVariantId}
        isSimulating={() => false}
        initiateOrder={onAttemptRedemption}
        ctaState={ctaState}
        useRewardCoins={useRewardCoins}
        setUseRewardCoins={setUseRewardCoins}
        dueAmount={redemptionAmount}
        redemptionAmount={redemptionAmount}
        brand={brand}
        isCoinsOnly={true}
        allowRewardCoins={false}
        showHubbleCoinRewards={false}
        hubbleCoinRewards={hubbleCoinRewards}
        coinsBalance={props.coinsAvailable}
        rewardDiscountAmount={() => 0}
        initialErrorOnSimulate={() => null}
        isDarkTheme={true}
        buildHeader={() => (
          <>
            <div class="flex w-full flex-col items-center justify-center gap-4 px-2 pt-2">
              <CardGiftV3
                plainLogoUrl={props.brand.voucherProductMetadata.plainLogoUrl}
                title={props.brand.voucherProductMetadata.title}
                cardBackgroundColor={
                  props.brand.voucherProductMetadata.cardBackgroundColor
                }
                heroImageUrl={props.brand.voucherProductMetadata.heroImageUrl}
                borderRadius={14}
              />
              <span class="text-smallBold uppercase tracking-[1.1px] text-[#757575]">
                {`${props.brand.name} gift card value`}
              </span>
            </div>
          </>
        )}
        buildInsufficientCoins={() => <InsufficientCoins />}
      />
    );
  }

  function InsufficientCoins() {
    return (
      <div class="flex flex-row items-center justify-center font-inter ">
        <span class="text-[15px]  font-semibold text-textNormal">
          You only have
        </span>
        <img src={coinIcon} class="ml-2 mr-1 h-[21px] w-[21px]" />
        <span class="text-medium font-semibold text-white">
          {toIndianNumber(props.coinsAvailable)}
        </span>
      </div>
    );
  }
}

function modifyBrandAmountConditions(props: {
  brand: VoucherProduct;
  coinsAvailable: number;
}): VoucherProduct {
  let newMaxAmount = Math.min(
    props.brand.amountConditions.maxAmount,
    Math.max(2000, props.coinsAvailable)
  );

  let modifiedBrand = {
    ...props.brand,
    amountConditions: {
      ...props.brand.amountConditions,
      maxAmount: newMaxAmount,
    },
  } as VoucherProduct;
  return modifiedBrand;
}
